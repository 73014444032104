import React from "react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { useAccount } from "wagmi";
import { useAtom } from "jotai";
import { API_URL } from "@/constants";
import { Decimal } from "@/util/decimal";
import {
  STATS_ATOM,
  FARM_VAULTS_ATOM,
  MODE_FARM_VAULTS_ATOM,
} from "./-new-atoms";
import { mainnet, mode } from "viem/chains";

export const Route = createFileRoute("/_points/points/")({
  component: Points,
});

function Points() {
  const { address: userAddress } = useAccount();
  const [stats, setStats] = useAtom(STATS_ATOM);
  const [farmVaults, setFarmVaults] = useAtom(FARM_VAULTS_ATOM);
  const [modeFarmVaults, setModeFarmVaults] = useAtom(MODE_FARM_VAULTS_ATOM);

  // Fetch stats
  React.useEffect(() => {
    setStats((d) => {
      d.fetchStats = true;
    });
    fetch(`${API_URL}/stats`)
      .then((response) => response.json())
      .then((data) => {
        setStats((d) => {
          d.tvl = Decimal.from(data.tvl);
          d.totalPointsDistributed = Decimal.from(data.total_points_number);
        });
      })
      .finally(() => {
        setStats((d) => {
          d.fetchStats = false;
        });
      });
  }, []);

  // Fetch user stats
  React.useEffect(() => {
    if (!userAddress) {
      return;
    }
    const url = `${API_URL}/v2/points/${userAddress}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setStats((d) => {
          d.yourTotalPoints = Decimal.from(data.total_points_number);
        });

        const vaults = data.vaults;

        if (Object.keys(vaults).length === 0) {
          return;
        }

        setFarmVaults((d) => {
          for (const name in data.vaults) {
            if (name.startsWith("mode_")) {
              continue;
            }
            const totalAssets = vaults[name].deposit_number;
            const pricedTotalAssets = vaults[name].priced_deposit_number;
            const k = name as keyof typeof farmVaults;
            d[k].totalAssets = Decimal.from(totalAssets);
            d[k].pricedTotalAssets = Decimal.from(pricedTotalAssets);
            d[k].userVaultBalance = Decimal.from(totalAssets);
            d[k].userPoints = vaults[name].points_number;
          }
        });

        setModeFarmVaults((d) => {
          for (const name in data.vaults) {
            if (!name.startsWith("mode_")) {
              continue;
            }
            const totalAssets = vaults[name].deposit_number;
            const pricedTotalAssets = vaults[name].priced_deposit_number;
            const k = name as keyof typeof modeFarmVaults;
            d[k].totalAssets = Decimal.from(totalAssets);
            d[k].pricedTotalAssets = Decimal.from(pricedTotalAssets);
            d[k].userVaultBalance = Decimal.from(totalAssets);
            d[k].userPoints = vaults[name].points_number;
          }
        });
      })
      .finally(() => {
        // Do something
      });
  }, [userAddress]);

  return (
    <>
      <div className="text-cetaceanblue dark:text-white">
        <div className="flex items-center">
          <Link className="hover:text-rosebonbon" to="/">
            {" "}
            Home{" "}
          </Link>
          <span className="mx-2">→</span>
          <span>Vaults</span>
        </div>

        <h1 className="text-5xl font-bold mt-2">Earn Points</h1>
        <p>Deposits will earn Eigenlayer, LRT, and Ebisu points!</p>
      </div>

      <div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-4">
          <div className="bg-white dark:bg-black rounded-md p-2 lg:p-4">
            <div className="flex flex-col">
              <h3 className="text-xl lg:text-3xl font-medium text-cetaceanblue dark:text-white">
                {stats.fetchStats ? "-" : `$ ${stats.tvl.shorten()}`}
              </h3>
              <p className="opacity-40 dark:opacity-80">TVL</p>
            </div>
          </div>

          <div className="bg-white dark:bg-black rounded-md p-2 lg:p-4">
            <div className="flex flex-col">
              <h3 className="text-xl lg:text-3xl font-medium text-cetaceanblue dark:text-white">
                {stats.fetchStats
                  ? "-"
                  : stats.totalPointsDistributed.shorten()}
              </h3>
              <p className="opacity-40 dark:opacity-80">Points distributed</p>
            </div>
          </div>

          <div className="bg-white dark:bg-black rounded-md p-2 lg:p-4">
            <div className="flex flex-col">
              <h3 className="text-xl lg:text-3xl font-medium text-cetaceanblue dark:text-white">
                {stats.yourTotalPoints.shorten()}
              </h3>
              <p className="opacity-40 dark:opacity-80">Your points</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h2 className="text-xl font-medium mb-2 flex items-center gap-2">
          <div className="h-8 w-8 rounded-full border-black align-center dark:border-white p-[2px] relative">
            <img
              className="w-full rounded-full"
              src="/networks/eth.svg"
              alt=""
            />
          </div>
          <span>Vaults &middot; Ethereum</span>
        </h2>
        <VaultList
          vaults={farmVaults}
          comingSoonList={[
            {
              name: "Yield Nest",
              lrtTokenName: "Yield Nest ynETH",
              mediaUrl: "/tokens/ynETH.svg",
            },
          ]}
        />
      </div>

      <div>
        <h2 className="text-xl font-medium mb-2 flex items-center gap-2">
          <div className="h-8 w-8 rounded-full border-black align-center dark:border-white p-[2px] relative">
            <img
              className="w-full rounded-full"
              src="/networks/mode.avif"
              alt=""
            />
          </div>
          <span>Vaults &middot; Mode Network</span>
        </h2>

        <VaultList vaults={modeFarmVaults} chainId={mode.id} />
      </div>
    </>
  );
}

function VaultList({
  vaults,
  chainId,
  comingSoonList,
}: { vaults: any; chainId?: number; comingSoonList?: any }) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
      {Object.keys(vaults).map((key) => {
        const vault = vaults[key as keyof typeof vaults];
        return (
          <Link
            key={key}
            className="bg-white dark:bg-black rounded-md p-2 lg:p-4 inline-block transition-all duration-50 ease-in-out hover:scale-[102%]"
            to="/points/$vault"
            params={{ vault: key }}
          >
            <div className="flex gap-2">
              <div className="h-8 w-8 border rounded-full border-black align-center dark:border-white p-[2px] relative">
                <img className="w-full" src={vault.imageUrl} alt="" />
                {chainId === mode.id && (
                  <img
                    className="h-4 absolute bottom-0 right-[-4px]"
                    src="/networks/mode.avif"
                    alt=""
                  />
                )}
              </div>
              <div>
                <h2 className="text-xl lg:text-xl font-medium text-cetaceanblue dark:text-white">
                  {vault.title}
                </h2>
                <p className="opacity-40 dark:opacity-60 dark:text-white">
                  Your deposit:&nbsp;
                  {vault.totalAssets.prettify()}&nbsp;
                  <span className="opacity-60">
                    (${vault.pricedTotalAssets.prettify()})
                  </span>
                </p>
              </div>
            </div>
          </Link>
        );
      })}
      {comingSoonList?.map((item: ComingSoonProps) => (
        <ComingSoon key={item.name} {...item} />
      ))}
    </div>
  );
}

interface ComingSoonProps {
  name: string;
  lrtTokenName: string;
  mediaUrl: string;
}

function ComingSoon(props: ComingSoonProps) {
  return (
    <div className="bg-white dark:bg-black rounded-md p-2 lg:p-4 inline-block transition-all duration-50 ease-in-out hover:scale-[102%]">
      <div className="flex gap-2">
        <div className="h-8 w-8 border rounded-full border-black align-center dark:border-white p-[2px] relative">
          <img className="w-full" src={props.mediaUrl} alt="" />
        </div>
        <div>
          <h2 className="text-xl lg:text-xl font-medium text-cetaceanblue dark:text-white">
            {props.lrtTokenName}
          </h2>
          <p className="text-orange-600">Coming soon!</p>
        </div>
      </div>
    </div>
  );
}
