import React from "react";
import { Decimal } from "@/util/decimal";
import erc20ABI from "@/abis/erc20.json";
import farmVaultABI from "@/abis/farm-vault.json";
import { useReadContracts } from "wagmi";
import type { Address } from "viem";
import type { ModeVaultName, VaultName } from "./-new-atoms";
import {
  getContracts,
  FARM_VAULTS_ATOM,
  MODE_FARM_VAULTS_ATOM,
} from "./-new-atoms";
import { useAtom } from "jotai";

const MULTICALL_ADDRESS =
  "0xcA11bde05977b3631167028862bE2a173976CA11" as Address;

export function useVault(
  vaultName: string,
  userAddress?: Address,
  chainId?: number,
) {
  const [farmVaults, setFarmVaults] = useAtom(FARM_VAULTS_ATOM);
  const [modeFarmVaults, setModeFarmVaults] = useAtom(MODE_FARM_VAULTS_ATOM);
  const isModeVault = vaultName.startsWith("mode_");

  const contracts = getContracts(
    vaultName as VaultName | ModeVaultName,
    chainId,
  );

  if (!contracts) {
    return null;
  }

  const vaultContract = {
    address: contracts.vault.address,
    abi: farmVaultABI,
  };

  const assetContract = {
    address: contracts.asset.address,
    abi: erc20ABI,
  };

  const { data, ...readVault } = useReadContracts<any>({
    contracts: [
      {
        ...vaultContract,
        functionName: "maxDeposit",
        args: [userAddress],
      },
      {
        ...vaultContract,
        functionName: "balanceOf",
        args: [userAddress],
      },
      {
        ...assetContract,
        functionName: "balanceOf",
        args: [userAddress],
      },
    ],
    multicallAddress: MULTICALL_ADDRESS,
  });

  React.useEffect(() => {
    if (!data) {
      return;
    }

    const ok = Object.values(data).every((item) => item.status === "success");
    if (!ok) {
      console.log("Error fetching vault data", data);
      return;
    }

    const [maxDeposit, balanceOfVaultShares, balanceOfAsset] = data;
    if (isModeVault) {
      setModeFarmVaults((v) => {
        const vault = v[vaultName as ModeVaultName];
        vault.userMaxDeposit = Decimal.fromBigInt(maxDeposit.result as bigint);
        vault.userVaultBalance = Decimal.fromBigInt(
          balanceOfVaultShares.result as bigint,
        );
        vault.userAssetBalance = Decimal.fromBigInt(
          balanceOfAsset.result as bigint,
        );
      });
      return;
    }
    setFarmVaults((v) => {
      const vault = v[vaultName as VaultName];
      vault.userMaxDeposit = Decimal.fromBigInt(maxDeposit.result as bigint);
      vault.userVaultBalance = Decimal.fromBigInt(
        balanceOfVaultShares.result as bigint,
      );
      vault.userAssetBalance = Decimal.fromBigInt(
        balanceOfAsset.result as bigint,
      );
    });
  }, [data, vaultName, isModeVault]);

  return {
    contracts,
    vault: isModeVault
      ? modeFarmVaults[vaultName as ModeVaultName]
      : farmVaults[vaultName as VaultName],
    refetchVault: readVault.refetch,
  };
}
