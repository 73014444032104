import { createFileRoute, Link, Outlet } from "@tanstack/react-router";
import { useTheme } from "@/components/theme-provider";
import { Moon, Sun } from "lucide-react";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export const Route = createFileRoute("/_points")({
  component: PointsLayout,
});

function PointsLayout() {
  const { theme, setTheme } = useTheme();

  return (
    <div className="bg-floralwhite dark:bg-cetaceanblue">
      <div className="flex flex-col max-w-4xl mx-auto dark:text-slategray gap-6 lg:gap-10 p-4">
        <div className="flex justify-between">
          <Link className="flex gap-1 items-center" to="/">
            <img
              className="h-6"
              src={theme === "light" ? "/logo-light.svg" : "/logo-dark.svg"}
              alt=""
            />
          </Link>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setTheme(theme === "light" ? "dark" : "light")}
              className="h-9 text-center rounded-xl px-3 hover:bg-palepink dark:border-slategray dark:text-slategray dark:hover:text-rosebonbon dark:hover:bg-cetaceanblue dark:hover:border-rosebonbon"
            >
              {theme === "light" ? <Sun size={18} /> : <Moon size={18} />}
            </button>
            <ConnectButton showBalance={false} />
          </div>
        </div>
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}

function Footer() {
  return (
    <div className="flex flex-col justify-center items-center gap-2 mt-4 lg:mt-6">
      <div className="text-sm text-center">
        <div className="flex items-center justify-center gap-2">
          <a
            className="underline"
            target="_blank"
            href="https://twitter.com/Ebisu_Finance"
          >
            Twitter
          </a>
          <a
            className="underline"
            target="_blank"
            href="https://t.me/Ebisu_Finance_Updates"
          >
            Telegram
          </a>
          <a
            className="underline"
            target="_blank"
            href="https://github.com/ebisufinance"
          >
            Github
          </a>
        </div>

        <div className="mt-4">
          <h2 className="font-bold">Audits</h2>
          <div className="flex items-center justify-center gap-2">
            <a
              className="underline"
              target="_blank"
              href="/PeckShield-Audit-Report-EbisuVault-v1.0.pdf"
            >
              Peckshield
            </a>
            <a
              className="underline"
              target="_blank"
              href="https://github.com/pashov/audits/blob/master/team/pdf/Ebisu-security-review.pdf"
            >
              Pashov
            </a>
          </div>
        </div>
      </div>
      <p className="opacity-40 text-sm">© 2024</p>
    </div>
  );
}
