import { cn } from "@/util/css";
import * as styles from "@/styles";
import { TextWithLoader } from "@/components/text-with-loader";
import type { Decimal } from "@/util/decimal";

const btnStyles = styles.TransactionButton;

interface WithdrawProps {
  withdrawAmount: string;
  withdraw: { isLoading: boolean; isConfirmed: boolean };
  vault: { userVaultBalance: Decimal; symbol: string };
  canWithdrawToken: boolean;
  onWithdraw: () => void;
  setWithdrawAmount: (value: string) => void;
}

export function Withdraw(props: WithdrawProps) {
  const {
    withdrawAmount,
    vault,
    withdraw,
    canWithdrawToken,
    setWithdrawAmount,
    onWithdraw,
  } = props;

  return (
    <div className="flex flex-col gap-4 lg:gap-6">
      <div className="flex flex-col">
        <div className="flex justify-between text-base font-medium">
          <div>Amount</div>
          <button
            type="button"
            onClick={() => {
              setWithdrawAmount(vault.userVaultBalance.toString());
            }}
            className="text-base hover:text-rosebonbon"
          >
            Max: {vault.userVaultBalance.toString()} EBISU{vault.symbol}
          </button>
        </div>

        <input
          onWheel={(e) => e.currentTarget.blur()}
          value={withdrawAmount}
          onChange={(e) => {
            setWithdrawAmount(e.target.value);
          }}
          placeholder="0.0"
          className="bg-white rounded-lg border-black border-2 w-full text-xl p-2 focus:outline-none focus:border-blue-500 focus:ring-0 dark:text-floralwhite dark:bg-oxfordblue dark:border-slategray"
          type="number"
        />
      </div>

      <div className="flex items-center gap-2 justify-center">
        <button
          type="button"
          className={cn(btnStyles.default, {
            [btnStyles.primary]: canWithdrawToken,
            [btnStyles.confirmed]: withdraw.isConfirmed,
            [btnStyles.pending]: withdraw.isLoading,
            [btnStyles.disabled]: !canWithdrawToken,
          })}
          disabled={!canWithdrawToken}
          onClick={onWithdraw}
        >
          <TextWithLoader
            text={`Withdraw ${vault.symbol}`}
            isLoading={withdraw.isLoading}
            iconSize={18}
          />
        </button>
      </div>
    </div>
  );
}
