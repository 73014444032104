import React from "react";
import { createFileRoute, Link } from "@tanstack/react-router";
import type { VaultName } from "./-new-atoms";
import { useVault } from "./-use-new-vault";
import { FARM_VAULTS_ATOM, MODE_FARM_VAULTS_ATOM } from "./-new-atoms";
import { useAtom } from "jotai";
import { cn } from "@/util/css";
import { Plus, Minus, ShieldEllipsis } from "lucide-react";
import * as styles from "@/styles";
import { useApproveERC20 } from "@/hooks/use-approve-erc20";
import { useWriteContract } from "@/hooks/use-write-contract";
import { Decimal } from "@/util/decimal";
import farmVaultABI from "@/abis/farm-vault.json";
import { useAccount, useSwitchChain } from "wagmi";
import { mode, mainnet } from "wagmi/chains";
import { API_URL } from "@/constants";
import * as faqs from "./-faqs";
import { Drawer } from "vaul";
import { ExternalLink } from "./-faqs";
import { shortenAddress } from "@/util/string";
import { Deposit } from "./-deposit";
import { Withdraw } from "./-withdraw";

export const Route = createFileRoute("/_points/points/$vault")({
  component: Vault,
});

const Tabs = {
  deposit: "deposit",
  withdraw: "withdraw",
};

function Vault() {
  const [farmVaults, setFarmVaults] = useAtom(FARM_VAULTS_ATOM);
  const [modeFarmVaults, setModeFarmVaults] = useAtom(MODE_FARM_VAULTS_ATOM);
  const [isFetchingApi, setIsFetchingApi] = React.useState(false);
  const { address: userAddress, chainId } = useAccount();
  const params = Route.useParams();

  const currentVault = useVault(params.vault, userAddress, chainId);

  if (!currentVault) {
    throw new Error(`Vault ${params.vault} not found`);
  }

  const vault = currentVault.vault;

  const [tab, setTab] = React.useState(Tabs.deposit);

  const [depositAmount, setDepositAmount] = React.useState("");
  const [withdrawAmount, setWithdrawAmount] = React.useState("");

  const depositAmountBn = React.useMemo(
    () => Decimal.safeParse(depositAmount),
    [depositAmount],
  );
  const withdrawAmountBn = React.useMemo(
    () => Decimal.safeParse(withdrawAmount),
    [withdrawAmount],
  );

  const maxDeposit = React.useMemo(() => {
    return Decimal.min(vault.userMaxDeposit, vault.userAssetBalance);
  }, [vault.userMaxDeposit, vault.userAssetBalance]);

  const approveToken = useApproveERC20({
    spender: currentVault.contracts.vault.address,
    token: currentVault.contracts.asset.address,
  });

  const isTokenApproved = React.useMemo(() => {
    return approveToken.allowance.gte(depositAmountBn);
  }, [approveToken.allowance, depositAmountBn]);

  const deposit = useWriteContract({
    okMessage: "You have deposited into the vault.",
  });

  const withdraw = useWriteContract({
    okMessage: "You have withdrawn from the vault.",
  });

  React.useEffect(() => {
    if (deposit.isConfirmed) {
      setDepositAmount("");
      approveToken.refetchAllowance();
      currentVault.refetchVault();
    }
  }, [deposit.isConfirmed]);

  React.useEffect(() => {
    if (withdraw.isConfirmed) {
      setWithdrawAmount("");
      currentVault.refetchVault();
    }
  }, [withdraw.isConfirmed]);

  const canDepositToken = React.useMemo(() => {
    if (depositAmountBn.isZero) {
      return false;
    }
    if (deposit.isLoading) {
      return false;
    }
    if (!userAddress) {
      return false;
    }
    return approveToken.allowance.gte(depositAmount);
  }, [deposit.isLoading, userAddress, depositAmountBn, approveToken.allowance]);

  const canWithdrawToken = React.useMemo(() => {
    if (withdrawAmountBn.isZero) {
      return false;
    }
    if (withdraw.isLoading) {
      return false;
    }
    if (!userAddress) {
      return false;
    }
    return vault.userVaultBalance.gte(withdrawAmountBn);
  }, [withdraw.isLoading, userAddress, withdrawAmountBn, vault]);

  React.useEffect(() => {
    if (!userAddress) {
      return;
    }
    const url = `${API_URL}/v2/points/${userAddress}`;
    setIsFetchingApi(true);
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const vaults = data.vaults;
        if (Object.keys(vaults).length === 0) {
          return;
        }
        setFarmVaults((d) => {
          for (const name in data.vaults) {
            if (name.startsWith("mode_")) {
              continue;
            }
            const k = name as keyof typeof farmVaults;
            d[k].userPoints = vaults[name].points_number;
          }
        });
        setModeFarmVaults((d) => {
          for (const name in data.vaults) {
            if (!name.startsWith("mode_")) {
              continue;
            }
            const k = name as keyof typeof modeFarmVaults;
            d[k].userPoints = vaults[name].points_number;
          }
        });
      })
      .catch((e) => {
        // TODO
      })
      .finally(() => {
        setIsFetchingApi(false);
      });
  }, [userAddress]);

  let FaqComponent: React.ComponentType | null = null;

  switch (params.vault) {
    case "weeth":
      FaqComponent = faqs.WeethFAQ;
      break;
    case "ezeth":
      FaqComponent = faqs.EzethFAQ;
      break;
    case "pufeth":
      FaqComponent = faqs.PufethFAQ;
      break;
    case "swell_rsweth":
      FaqComponent = faqs.Swell_rswethFAQ;
      break;
    case "kelp_rseth":
      FaqComponent = faqs.Kelp_rsethFAQ;
      break;
  }

  return (
    <>
      <div className="text-cetaceanblue dark:text-white flex gap-2 flex-col">
        <div className="flex items-center">
          <VaultIcon vaultName={params.vault} />

          <Link className="hover:text-rosebonbon" to="/points/">
            Vaults
          </Link>
          <span className="mx-2">→</span>
          <span>{vault.symbol}</span>
        </div>
        <div className="flex justify-between">
          <h1 className="text-5xl font-bold">{vault.title}</h1>
          <AuditDrawer />
        </div>
        <p>
          Deposit {vault.symbol} and earn Eigenlayer, LRT, and Ebisu points!
        </p>
      </div>

      <ChainPrompt vaultName={params.vault as VaultName} chainId={chainId} />

      {vault.userVaultBalance.gt(0) && (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-4">
          <div className="bg-white dark:bg-black rounded-md p-2 lg:p-4">
            <div className="flex flex-col">
              <h3 className="text-xl lg:text-3xl font-medium text-cetaceanblue dark:text-white">
                {vault.userVaultBalance.prettify(4)}
              </h3>
              <p className="opacity-40 dark:opacity-80">
                Deposits in {vault.symbol}
              </p>
            </div>
          </div>
          <div className="bg-white dark:bg-black rounded-md p-2 lg:p-4">
            <div className="flex flex-col">
              <h3 className="text-xl lg:text-3xl font-medium text-cetaceanblue dark:text-white">
                {Decimal.from(vault.userPoints).prettify(0)}
              </h3>
              <p className="opacity-40 dark:opacity-80">Points</p>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white rounded-xl w-full flex flex-col dark:bg-oxfordblue">
        <div className="grid grid-cols-2">
          <button
            type="button"
            onClick={() => setTab(Tabs.deposit)}
            className={cn(
              "font-bold flex justify-center space-x-2 items-center w-full rounded-2xl rounded-tl-none rounded-bl-none rounded-tr-none p-2 md:px-8 py-4 text-left",
              {
                "dark:text-white": tab === Tabs.deposit,
                "bg-gray-100 dark:bg-[#101730] rounded-tl-xl opacity-90 hover:bg-gray-100/50":
                  tab === Tabs.withdraw,
              },
            )}
          >
            <Plus size={24} />
            <b className="text-lg md:text-xl">Deposit</b>
          </button>
          <button
            type="button"
            onClick={() => setTab(Tabs.withdraw)}
            className={cn(
              "font-bold flex justify-center space-x-2 items-center w-full rounded-2xl rounded-br-none rounded-tl-none rounded-tr-none p-2 md:px-8 py-4 text-left",
              {
                "border-transparent dark:text-white": tab === Tabs.withdraw,
                "bg-gray-100 dark:bg-[#101730] rounded-tr-xl opacity-90 hover:bg-gray-100/50":
                  tab === Tabs.deposit,
              },
            )}
          >
            <Minus size={24} />
            <b className="text-lg md:text-xl">Withdraw</b>
          </button>
        </div>

        <div className="p-4">
          {tab === Tabs.deposit && (
            <Deposit
              depositAmount={depositAmount}
              maxDeposit={maxDeposit}
              vault={vault}
              approveToken={approveToken}
              isTokenApproved={isTokenApproved}
              deposit={deposit}
              canDepositToken={canDepositToken}
              setDepositAmount={setDepositAmount}
              onApprove={() => approveToken.approve(depositAmountBn?.bigNumber)}
              onDeposit={() =>
                deposit.writeContract({
                  address: currentVault.contracts.vault.address,
                  abi: farmVaultABI,
                  functionName: "deposit",
                  args: [depositAmountBn?.bigNumber, userAddress],
                })
              }
            />
          )}

          {tab === Tabs.withdraw && (
            <Withdraw
              withdrawAmount={withdrawAmount}
              withdraw={withdraw}
              vault={vault}
              canWithdrawToken={canWithdrawToken}
              setWithdrawAmount={setWithdrawAmount}
              onWithdraw={() => {
                withdraw.writeContract({
                  address: currentVault.contracts.vault.address,
                  abi: farmVaultABI,
                  functionName: "withdraw",
                  args: [withdrawAmountBn?.bigNumber, userAddress, userAddress],
                });
              }}
            />
          )}
        </div>

        <div className="p-4 opacity-50 text-center text-sm">
          <VaultAddressLink
            vaultName={params.vault}
            vaultAddress={currentVault.contracts.vault.address}
          />
        </div>
      </div>

      {tab === Tabs.deposit && FaqComponent && (
        <div className="bg-white rounded-xl w-full flex flex-col dark:bg-oxfordblue p-4">
          <FaqComponent />
        </div>
      )}
    </>
  );
}

function VaultAddressLink(props: { vaultName: string; vaultAddress: string }) {
  if (props.vaultName.startsWith("mode_")) {
    return (
      <ExternalLink
        href={`${mode.blockExplorers.default.url}/address/${props.vaultAddress}`}
      >
        {shortenAddress(props.vaultAddress)}
      </ExternalLink>
    );
  }
  return (
    <ExternalLink
      href={`${mainnet.blockExplorers.default.url}/address/${props.vaultAddress}`}
    >
      {shortenAddress(props.vaultAddress)}
    </ExternalLink>
  );
}

function AuditDrawer() {
  return (
    <Drawer.Root activeSnapPoint={1}>
      <Drawer.Trigger asChild>
        <button className="flex items-center text-xl text-green-500 hover:text-rosebonbon">
          <ShieldEllipsis size={24} />
          <span>Audited</span>
        </button>
      </Drawer.Trigger>
      <Drawer.Overlay className="fixed inset-0 bg-black/40" />
      <Drawer.Portal>
        <Drawer.Content className="fixed flex flex-col bg-gray-100 dark:bg-[#041137] rounded-t-2xl bottom-0 left-0 right-0 h-full max-h-[420px] mx-[-1px] dark:text-white">
          <div
            className={cn(
              "flex flex-col items-center justify-center w-full p-4 pt-5 gap-4",
              {
                "overflow-hidden": true,
              },
            )}
          >
            <h2 className="text-4xl font-medium mb-4">Audits by</h2>

            <div className="flex items-center gap-6 lg:gap-12">
              <div className="flex flex-col gap-2 justify-center items-center">
                <img
                  src="/audits/peckshield.jpg"
                  className="rounded-full w-[100px]"
                />
                <span className="text-xl">Peckshield</span>
                <a
                  target="_blank"
                  href="/PeckShield-Audit-Report-EbisuVault-v1.0.pdf"
                  className="underline hover:text-rosebonbon"
                >
                  View audit
                </a>
              </div>

              <div className="flex flex-col gap-2 justify-center items-center">
                <img
                  src="/audits/pashov.jpg"
                  className="rounded-full w-[100px]"
                />
                <span className="text-xl">Pashov</span>
                <a
                  target="_blank"
                  href="https://github.com/pashov/audits/blob/master/team/pdf/Ebisu-security-review.pdf"
                  className="underline hover:text-rosebonbon"
                >
                  View audit
                </a>
              </div>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

interface ChainPromptProps {
  vaultName: string;
  chainId?: number;
}

function ChainPrompt(props: ChainPromptProps) {
  const { switchChain } = useSwitchChain();

  if (!props.chainId) {
    return null;
  }

  if (props.vaultName.startsWith("mode_") && props.chainId !== mode.id) {
    return (
      <div className="bg-white dark:bg-[#F24C9E] rounded-md p-2 lg:p-4">
        <div className="flex justify-between items-center">
          <h3 className="text-xl lg:text-xl font-medium text-cetaceanblue dark:text-white flex items-center gap-2">
            <img src="/networks/mode.avif" className="h-6" alt="" />
            <span>This vault is on Mode Network</span>
          </h3>
          <button
            onClick={() => switchChain({ chainId: mode.id })}
            className="border-2 border-black rounded-full px-4 py-2 bg-[#dffe00] text-black hover:bg-gray-400"
          >
            Switch network
          </button>
        </div>
      </div>
    );
  }

  if (!props.vaultName.startsWith("mode_") && props.chainId !== mainnet.id) {
    return (
      <div className="bg-white dark:bg-[#F24C9E] rounded-md p-2 lg:p-4">
        <div className="flex justify-between items-center">
          <h3 className="text-xl lg:text-xl font-medium text-cetaceanblue dark:text-purple-100 flex items-center gap-2">
            <img src="/networks/eth.svg" className="h-6" alt="" />
            <span>This vault is on Ethereum</span>
          </h3>
          <button
            onClick={() => switchChain({ chainId: mainnet.id })}
            className="border-2 border-black rounded-full px-4 py-2 bg-purple-100 text-black hover:bg-gray-400 dark:border-purple-500"
          >
            Switch network
          </button>
        </div>
      </div>
    );
  }

  return null;
}

function VaultIcon(props: { vaultName: string }) {
  if (props.vaultName.startsWith("mode_")) {
    return <img src="/networks/mode.avif" className="h-6 mr-2" alt="" />;
  }
  return <img src="/networks/eth.svg" className="h-6 mr-2" alt="" />;
}
