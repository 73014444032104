import { defineChain } from 'viem'

export const omniOmega = defineChain({
  id: 164,
  name: 'Omni Omega',
  nativeCurrency: { name: 'OMNI', symbol: 'OMNI', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://omega.omni.network'] },
  },
  blockExplorers: {
    default: { name: 'Omni Omega Explorer', url: 'https://omega.omniscan.network/' },
  }
})