import { TextWithLoader } from "@/components/text-with-loader";
import { cn } from "@/util/css";
import type { Decimal } from "@/util/decimal";
import * as styles from "@/styles";
import { MoveRight, Plus } from "lucide-react";

const btnStyles = styles.TransactionButton;

interface DepositProps {
  depositAmount: string;
  maxDeposit: Decimal;
  approveToken: { isLoading: boolean; isConfirmed: boolean };
  deposit: { isLoading: boolean; isConfirmed: boolean };
  isTokenApproved: boolean;
  vault: { symbol: string };
  canDepositToken: boolean;
  onDeposit: () => void;
  onApprove: () => void;
  setDepositAmount: (value: string) => void;
}

export function Deposit(props: DepositProps) {
  const {
    depositAmount,
    maxDeposit,
    vault,
    approveToken,
    isTokenApproved,
    deposit,
    canDepositToken,
    setDepositAmount,
    onApprove,
    onDeposit,
  } = props;
  return (
    <div className="flex flex-col gap-4 lg:gap-6">
      <div className="flex flex-col">
        <div className="flex justify-between text-base font-medium">
          <label>Amount</label>
          <button
            onClick={() => {
              setDepositAmount(maxDeposit.toString());
            }}
            className="text-base hover:text-rosebonbon"
            type="button"
          >
            Max: {maxDeposit.prettify(4)} {vault.symbol}
          </button>
        </div>

        <input
          onWheel={(e) => e.currentTarget.blur()}
          value={depositAmount}
          onChange={(e) => {
            setDepositAmount(e.target.value);
          }}
          placeholder="0.0"
          className="font-sans bg-white rounded-lg border-black border-2 w-full text-xl p-2 focus:outline-none focus:border-blue-500 focus:ring-0 dark:text-floralwhite dark:bg-oxfordblue dark:border-slategray"
          type="number"
        />
      </div>

      <div className="flex items-center gap-2 justify-center">
        <button
          type="button"
          className={cn([btnStyles.default, btnStyles.secondary], {
            [btnStyles.confirmed]: approveToken.isConfirmed,
            [btnStyles.pending]: approveToken.isLoading,
            [btnStyles.disabled]: approveToken.isLoading || isTokenApproved,
          })}
          disabled={approveToken.isLoading || isTokenApproved}
          onClick={onApprove}
        >
          <TextWithLoader
            text={isTokenApproved ? "Approved" : `Approve ${vault.symbol}`}
            isLoading={approveToken.isLoading}
            icon={Plus}
            iconSize={18}
          />
        </button>
        <MoveRight size={18} />
        <button
          type="button"
          className={cn([btnStyles.default, btnStyles.primary], {
            [btnStyles.confirmed]: deposit.isConfirmed,
            [btnStyles.pending]: deposit.isLoading,
            [btnStyles.disabled]: !canDepositToken,
          })}
          disabled={!canDepositToken}
          onClick={onDeposit}
        >
          <TextWithLoader
            text="Deposit"
            isLoading={deposit.isLoading}
            iconSize={18}
          />
        </button>
      </div>
    </div>
  );
}
