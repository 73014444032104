import { Rocket } from "lucide-react";

export function LaunchAppBanner() {
  return (
    <div className="bg-gradient-to-r from-[#F090CA] to-[#AB80F8] p-3 text-lg">
      <div className="flex items-center justify-center gap-3">
        <div className="flex-shrink-0">
          <Rocket className="text-white" />
        </div>
        <p className="text-white font-semibold">
          Gbisu, Ebisu Money public testnet is now live.{" "}
          <a
            className="underline hover:text-[#FFE0F0] transition-colors inline-flex"
            target="_blank"
            href="https://x.com/ebisu_finance/status/1833140181001785601"
          >
            View Announcement
          </a>
        </p>
      </div>
    </div>
  );
}
