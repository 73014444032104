import { Decimal } from "@/util/decimal";
import { atomWithImmer } from "jotai-immer";
import { mainnet, sepolia, mode } from "wagmi/chains";
import type { Address } from "viem";

export const STATS_ATOM = atomWithImmer({
  tvl: Decimal.ZERO,
  totalPointsDistributed: Decimal.ZERO,
  yourTotalPoints: Decimal.ZERO,
  fetchStats: false,
});

export type VaultName =
  | "weeth"
  | "ezeth"
  | "pufeth"
  | "swell_rsweth"
  | "kelp_rseth";

export type ModeVaultName = "mode_weeth" | "mode_ezeth";

export type Token = {
  symbol: string;
  address: Address;
};

export type Vault = {
  vault: Token;
  asset: Token;
};

const sepoliaContracts = [
  "weeth",
  "ezeth",
  "pufeth",
  "swell_rsweth",
  "kelp_rseth",
].reduce(
  (acc: Record<string, Vault>, name: string) => {
    acc[name] = {
      vault: {
        symbol: "points",
        address: "0x03853F2729e6713825D3371Aa14e0aD7caaDcBF5",
      },
      asset: {
        symbol: name,
        address: "0x727852ea26013b77f02B040d4c2775DDa222D3B8",
      },
    };
    return acc;
  },
  {} as Record<VaultName, Vault>,
);

const mainnetContracts: Record<VaultName, Vault> = {
  weeth: {
    vault: {
      symbol: "points",
      address: "0x221042C39EfFfCfF8FaD051032FEF583019F165F",
    },
    asset: {
      symbol: "weETH",
      address: "0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee",
    },
  },
  ezeth: {
    vault: {
      symbol: "points",
      address: "0xA8c3fc43Fa3992b315B8a63fe87386211F220669",
    },
    asset: {
      symbol: "ezETH",
      address: "0xbf5495efe5db9ce00f80364c8b423567e58d2110",
    },
  },
  pufeth: {
    vault: {
      symbol: "points",
      address: "0x950a316587B7a9277aDf13A5f4327c959472f030",
    },
    asset: {
      symbol: "pufETH",
      address: "0xd9a442856c234a39a81a089c06451ebaa4306a72",
    },
  },
  swell_rsweth: {
    vault: {
      symbol: "points",
      address: "0x6da1a9307fBf1d0a00EAF3B151f370b1925AB7e4",
    },
    asset: {
      symbol: "rswETH",
      address: "0xfae103dc9cf190ed75350761e95403b7b8afa6c0",
    },
  },
  kelp_rseth: {
    vault: {
      symbol: "points",
      address: "0x270C6FA5a206Bfc1382C6101e3F366a632bE80a2",
    },
    asset: {
      symbol: "rsETH",
      address: "0xa1290d69c65a6fe4df752f95823fae25cb99e5a7",
    },
  },
};

const modeNetworkContracts: Record<ModeVaultName, Vault> = {
  mode_weeth: {
    vault: {
      symbol: "points",
      address: "0x46b1a9e1baa54e1edda42d3831d6a48ad527900c",
    },
    asset: {
      symbol: "weETH",
      address: "0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A",
    },
  },
  mode_ezeth: {
    vault: {
      symbol: "points",
      address: "0xe3583d7efc9d33269615b1c8fd0ff5836b176948",
    },
    asset: {
      symbol: "ezETH",
      address: "0x2416092f143378750bb29b79eD961ab195CcEea5",
    },
  },
};

export const CONTRACTS = {
  [sepolia.id]: sepoliaContracts,
  [mainnet.id]: mainnetContracts,
  [mode.id]: modeNetworkContracts,
};

export function getContracts(
  name: VaultName | ModeVaultName,
  chainId?: number,
) {
  if (chainId) {
    const contracts = CONTRACTS[chainId as keyof typeof CONTRACTS] as Record<
      VaultName | ModeVaultName,
      Vault
    >;
    if (contracts && contracts[name]) {
      return contracts[name];
    }
  }

  // Chain ID doesn't matter
  const allContracts = { ...mainnetContracts, ...modeNetworkContracts };
  return allContracts[name];
}

export type Fields = {
  name: string;
  symbol: string;
  title: string;
  url: string;
  imageUrl: string;
  price: Decimal;
  totalAssets: Decimal;
  pricedTotalAssets: Decimal;
  userVaultBalance: Decimal;
  userAssetBalance: Decimal;
  userMaxDeposit: Decimal;
  userPoints: number;
};

export const FARM_VAULTS_ATOM = atomWithImmer<Record<VaultName, Fields>>({
  weeth: {
    name: "weeth",
    symbol: "weETH",
    title: "Wrapped eETH",
    url: "ether.fi",
    imageUrl: "/tokens/weeth.webp",
    price: Decimal.ZERO,
    totalAssets: Decimal.ZERO,
    pricedTotalAssets: Decimal.ZERO,
    userVaultBalance: Decimal.ZERO,
    userAssetBalance: Decimal.ZERO,
    userMaxDeposit: Decimal.ZERO,
    userPoints: 0,
  },
  ezeth: {
    name: "ezeth",
    symbol: "ezETH",
    title: "Renzo Restaked ETH",
    url: "renzoprotocol.com",
    imageUrl: "/tokens/ezeth.svg",
    price: Decimal.ZERO,
    totalAssets: Decimal.ZERO,
    pricedTotalAssets: Decimal.ZERO,
    userVaultBalance: Decimal.ZERO,
    userAssetBalance: Decimal.ZERO,
    userMaxDeposit: Decimal.ZERO,
    userPoints: 0,
  },
  pufeth: {
    name: "pufeth",
    symbol: "pufETH",
    title: "pufETH",
    url: "puffer.fi",
    imageUrl: "/tokens/pufeth.webp",
    price: Decimal.ZERO,
    totalAssets: Decimal.ZERO,
    pricedTotalAssets: Decimal.ZERO,
    userVaultBalance: Decimal.ZERO,
    userAssetBalance: Decimal.ZERO,
    userMaxDeposit: Decimal.ZERO,
    userPoints: 0,
  },
  swell_rsweth: {
    name: "swell_rsweth",
    symbol: "rswETH",
    title: "Swell ETH",
    url: "swellnetwork.io",
    imageUrl: "/tokens/rsweth.webp",
    price: Decimal.ZERO,
    totalAssets: Decimal.ZERO,
    pricedTotalAssets: Decimal.ZERO,
    userVaultBalance: Decimal.ZERO,
    userAssetBalance: Decimal.ZERO,
    userMaxDeposit: Decimal.ZERO,
    userPoints: 0,
  },
  kelp_rseth: {
    name: "kelp_rseth",
    symbol: "rsETH",
    title: "Kelp DAO Restaked ETH",
    url: "kelpdao.xyz",
    imageUrl: "/tokens/rseth.webp",
    price: Decimal.ZERO,
    totalAssets: Decimal.ZERO,
    pricedTotalAssets: Decimal.ZERO,
    userVaultBalance: Decimal.ZERO,
    userAssetBalance: Decimal.ZERO,
    userMaxDeposit: Decimal.ZERO,
    userPoints: 0,
  },
});

export const MODE_FARM_VAULTS_ATOM = atomWithImmer<
  Record<ModeVaultName, Fields>
>({
  mode_weeth: {
    name: "mode_weeth",
    symbol: "weETH",
    title: "Wrapped eETH",
    url: "ether.fi",
    imageUrl: "/tokens/weeth.webp",
    price: Decimal.ZERO,
    totalAssets: Decimal.ZERO,
    pricedTotalAssets: Decimal.ZERO,
    userVaultBalance: Decimal.ZERO,
    userAssetBalance: Decimal.ZERO,
    userMaxDeposit: Decimal.ZERO,
    userPoints: 0,
  },
  mode_ezeth: {
    name: "mode_ezeth",
    symbol: "ezETH",
    title: "Renzo Restaked ETH",
    url: "renzoprotocol.com",
    imageUrl: "/tokens/ezeth.svg",
    price: Decimal.ZERO,
    totalAssets: Decimal.ZERO,
    pricedTotalAssets: Decimal.ZERO,
    userVaultBalance: Decimal.ZERO,
    userAssetBalance: Decimal.ZERO,
    userMaxDeposit: Decimal.ZERO,
    userPoints: 0,
  },
});
