import erc20ABI from "@/abis/erc20.json";
import { failToast, loadingToast, okToast } from "@/components/toasts";
import { Decimal } from "@/util/decimal";
import React from "react";
import type { Address } from "viem";
import {
  useAccount,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import type { BaseError } from "wagmi";

const TOAST_ID = "use-approve-erc20";

interface UseApproveERC20Input {
  token?: Address;
  spender?: Address;
}

export function useApproveERC20(input: UseApproveERC20Input) {
  const { address: userAddress } = useAccount();

  const { data: allowance, refetch: refetchAllowance } = useReadContract({
    address: input.token,
    abi: erc20ABI,
    functionName: "allowance",
    args: [userAddress, input.spender],
  });

  const {
    data: hash,
    isPending,
    error: approvalError,
    writeContract,
  } = useWriteContract();

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  function approve(amount: string) {
    if (!input.token || !input.spender) {
      failToast({
        id: TOAST_ID,
        text: "Token or spender not set.",
      });
      return;
    }
    writeContract({
      address: input.token,
      abi: erc20ABI,
      functionName: "approve",
      args: [input.spender, amount],
    });
  }

  React.useEffect(() => {
    if (isPending || isConfirming) {
      loadingToast({
        id: TOAST_ID,
        text: "Waiting for confirmation...",
      });
    }
  }, [isPending, isConfirming]);

  React.useEffect(() => {
    if (!approvalError) {
      return;
    }
    failToast({
      id: TOAST_ID,
      text: (approvalError as BaseError).shortMessage,
    });
  }, [approvalError]);

  React.useEffect(() => {
    if (!isConfirmed) {
      return;
    }
    okToast({
      id: TOAST_ID,
      text: "You have approved the contract to spend your token.",
    });
    refetchAllowance();
  }, [isConfirmed]);

  return {
    hash,
    isPending,
    isConfirming,
    isConfirmed,
    isLoading: isPending || isConfirming,
    error: approvalError as BaseError,
    approve,
    allowance: allowance
      ? Decimal.fromBigInt(allowance as bigint)
      : Decimal.ZERO,
    refetchAllowance,
  };
}
