import { failToast, loadingToast, okToast } from "@/components/toasts";
import React from "react";
import {
  useWaitForTransactionReceipt,
  useWriteContract as useWriteContractWagmi,
} from "wagmi";
import type { BaseError } from "wagmi";

const TOAST_ID = "use-write-contract";

interface UseWriteContractInput {
  okMessage?: string;
}

export function useWriteContract(input: UseWriteContractInput = {}) {
  const {
    data: hash,
    isPending,
    error: depositError,
    writeContract,
  } = useWriteContractWagmi();

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      confirmations: 1,
      pollingInterval: 12_000,
      hash,
    });

  React.useEffect(() => {
    if (isPending || isConfirming) {
      loadingToast({
        txHash: hash,
        id: TOAST_ID,
        text: "Waiting for confirmation...",
      });
    }
  }, [isPending, isConfirming]);

  React.useEffect(() => {
    if (!depositError) {
      return;
    }
    failToast({
      id: TOAST_ID,
      text: (depositError as BaseError).shortMessage,
    });
  }, [depositError]);

  React.useEffect(() => {
    if (!isConfirmed) {
      return;
    }
    okToast({
      txHash: hash,
      id: TOAST_ID,
      text: input.okMessage ?? "Transaction confirmed.",
    });
  }, [isConfirmed]);

  const isLoading = isPending || isConfirming;

  return {
    hash,
    isPending,
    isConfirming,
    isConfirmed,
    isLoading,
    error: depositError as BaseError,
    writeContract,
  };
}
