import { ArrowRight, Loader2 } from "lucide-react";
import type { LucideIcon } from "lucide-react";

interface LoadingTextProps {
  text: string;
  isLoading: boolean;
  iconSize?: number;
  icon?: LucideIcon;
}

export function TextWithLoader(props: LoadingTextProps) {
  return (
    <div className="flex items-center justify-center gap-2">
      {props.isLoading ? (
        <Loader2 className="animate-spin" size={props.iconSize ?? 14} />
      ) : props.icon ? (
        <props.icon size={props.iconSize ?? 14} />
      ) : (
        <ArrowRight size={props.iconSize ?? 14} />
      )}
      <span>{props.text}</span>
    </div>
  );
}
