import { ExternalLink as ExternalLinkIcon } from "lucide-react";
import { shortenAddress } from "@/util/string";

export function ExternalLink({
  href,
  children,
}: { href: string; children: any }) {
  return (
    <a
      className="pl-1 inline-flex gap-1 items-center underline"
      target="_blank"
      href={href}
    >
      {children}
      <ExternalLinkIcon size={14} />
    </a>
  );
}

export function EzethFAQ() {
  return (
    <>
      <h2 className="font-bold opacity-100 text-xl">How do I get ezETH?</h2>
      <p>
        ezETH's address is{" "}
        <ExternalLink href="https://etherscan.io/token/0xbf5495efe5db9ce00f80364c8b423567e58d2110">
          {shortenAddress("0xbf5495efe5db9ce00f80364c8b423567e58d2110")}
        </ExternalLink>
      </p>
      <p>
        ezETH is the liquid restaking token representing a user’s restaked
        position at Renzo. Users
        <ExternalLink href="https://app.renzoprotocol.com/restake">
          can deposit native ETH or LSTs
        </ExternalLink>{" "}
        to get ezETH or swap for ezETH using CowSwap.
      </p>

      <p>
        Learn more about
        <ExternalLink href="https://docs.renzoprotocol.com/docs/renzo/ezeth">
          ezETH here
        </ExternalLink>
        .
      </p>

      <div className="flex items-center gap-2">
        <a
          target="_blank"
          href="https://app.renzoprotocol.com/restake"
          className="text-black hover:bg-[#FF70A6] border-black rounded-full py-1 px-4 flex items-center gap-1 min-w-[120px] text-center justify-center dark:border-black bg-blue-200"
        >
          Use Renzo Protocol
        </a>
      </div>
    </>
  );
}

export function WeethFAQ() {
  return (
    <>
      <h2 className="font-bold opacity-100 text-xl">How do I get weETH?</h2>
      <p>
        weETH's address is{" "}
        <ExternalLink href="https://etherscan.io/token/0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee">
          {shortenAddress("0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee")}
        </ExternalLink>
      </p>
      <p>
        weETH is the wrapped non-rebasing version of eETH, ether.fi's Liquid
        Restaking Token. Users can{" "}
        <ExternalLink href="https://app.ether.fi/">
          stake LSTs on ether.fi's app and wrap eETH
        </ExternalLink>{" "}
        or use CowSwap to get weETH.
      </p>

      <p>
        Find out more about eETH or weETH on{" "}
        <ExternalLink href="https://etherfi.gitbook.io/etherfi/getting-started/faq">
          ether.fi's FAQ page
        </ExternalLink>
        .
      </p>

      <div className="flex items-center gap-2">
        <a
          target="_blank"
          href="https://app.ether.fi/"
          className="text-black hover:bg-[#FF70A6] border-black rounded-full py-1 px-4 flex items-center gap-1 min-w-[120px] text-center justify-center dark:border-black bg-blue-200"
        >
          Use ether.fi
        </a>
      </div>
    </>
  );
}

export function PufethFAQ() {
  return (
    <>
      <h2 className="font-bold opacity-100 text-xl">How do I get pufETH?</h2>
      <p>
        pufETH's address is{" "}
        <ExternalLink href="https://etherscan.io/token/0xd9a442856c234a39a81a089c06451ebaa4306a72">
          {shortenAddress("0xd9a442856c234a39a81a089c06451ebaa4306a72")}
        </ExternalLink>
      </p>
      <p>
        Puffer is a decentralized native liquid restaking protocol (nLRP) built
        on Eigenlayer.
      </p>
      <p>
        It makes native restaking on Eigenlayer more accessible, allowing anyone
        to run an Ethereum Proof of Stake (PoS) validator while supercharging
        their rewards.
      </p>

      <p>
        Find out more about pufETH on{" "}
        <ExternalLink href="https://docs.puffer.fi/">
          puffer.fi's docs page.
        </ExternalLink>
        .
      </p>

      <div className="flex items-center gap-2">
        <a
          target="_blank"
          href="https://www.puffer.fi/"
          className="text-black hover:bg-[#FF70A6] border-black rounded-full py-1 px-4 flex items-center gap-1 min-w-[120px] text-center justify-center dark:border-black bg-blue-200"
        >
          Use puffer.fi
        </a>
      </div>
    </>
  );
}

export function Swell_rswethFAQ() {
  return (
    <>
      <h2 className="font-bold opacity-100 text-xl">How do I get rswETH?</h2>
      <p>
        rswETH's address is{" "}
        <ExternalLink href="https://etherscan.io/token/0xfae103dc9cf190ed75350761e95403b7b8afa6c0">
          {shortenAddress("0xfae103dc9cf190ed75350761e95403b7b8afa6c0")}
        </ExternalLink>
      </p>
      <p>
        Swell is a non-custodial staking protocol with a mission to deliver the
        world’s best liquid staking and restaking experience, simplify access to
        DeFi, while securing the future of Ethereum and restaking services.
      </p>

      <p>
        Find out more about rswETH on{" "}
        <ExternalLink href="https://docs.swellnetwork.io/">
          swell.fi's docs page.
        </ExternalLink>
        .
      </p>

      <div className="flex items-center gap-2">
        <a
          target="_blank"
          href="https://app.swellnetwork.io/swell-l2"
          className="text-black hover:bg-[#FF70A6] border-black rounded-full py-1 px-4 flex items-center gap-1 min-w-[120px] text-center justify-center dark:border-black bg-blue-200"
        >
          Use Swell
        </a>
      </div>
    </>
  );
}

export function Kelp_rsethFAQ() {
  return (
    <>
      <h2 className="font-bold opacity-100 text-xl">How do I get rsETH?</h2>
      <p>
        rsETH's address is{" "}
        <ExternalLink href="https://etherscan.io/token/0xa1290d69c65a6fe4df752f95823fae25cb99e5a7">
          {shortenAddress("0xa1290d69c65a6fe4df752f95823fae25cb99e5a7")}
        </ExternalLink>
      </p>
      <p>
        rsETH is a Liquid Restaked Token (LRT) issued by Kelp DAO designed to
        offer liquidity to illiquid assets deposited into restaking platforms,
        such as EigenLayer. It aims to address the risks and challenges posed by
        the current offering of restaking
      </p>

      <p>
        Find out more about rsETH on{" "}
        <ExternalLink href="https://kelp.gitbook.io/kelp">
          kelp.fi's docs page.
        </ExternalLink>
        .
      </p>

      <div className="flex items-center gap-2">
        <a
          target="_blank"
          href="https://kelpdao.xyz/restake/"
          className="text-black hover:bg-[#FF70A6] border-black rounded-full py-1 px-4 flex items-center gap-1 min-w-[120px] text-center justify-center dark:border-black bg-blue-200"
        >
          Restake with Kelp
        </a>
      </div>
    </>
  );
}
