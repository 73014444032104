import { Decimal } from "@/util/decimal";

export const DEFAULT_CHAIN_ID = "1";

export const CRITICAL_COLLATERAL_RATIO = Decimal.from(1.5);
export const MINIMUM_COLLATERAL_RATIO = Decimal.from(1.2);
export const EBUSD_LIQUIDATION_RESERVE = Decimal.from(200);
export const EBUSD_MINIMUM_NET_DEBT = Decimal.from(1800);

export const TroveStatus = {
  nonExistent: 0n,
  active: 1n,
  closedByOwner: 2n,
  closedByLiquidation: 3n,
  closedByRedemption: 4n,
};

export const buildbearChain = {
  id: 17994,
  name: "BuildBear",
  network: "BB",
  nativeCurrency: {
    decimals: 18,
    name: "BB ETH",
    symbol: "BB ETH",
  },
  iconUrl: "https://faucet.buildbear.io/smallLogo.svg",
  rpcUrls: {
    public: {
      http: ["https://rpc.buildbear.io/cooperative-colossus-551e7111"],
    },
    default: {
      http: ["https://rpc.buildbear.io/cooperative-colossus-551e7111"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "Buildbear",
      url: "https://explorer.buildbear.io/cooperative-colossus-551e7111",
    },
    default: {
      name: "Buildbear",
      url: "https://explorer.buildbear.io/cooperative-colossus-551e7111",
    },
  },
};

export const API_URL = "https://api.ebisufinance.com";

export const backedBy = [
  {
    name: "0xBisBis",
    link: "https://twitter.com/dotdotdotd1",
    pfp: "bisbis.png",
    company: null,
  },
  {
    name: "0xMinion",
    link: "https://twitter.com/0xminion",
    pfp: "0xminion.jpeg",
    company: "GBV Capital",
  },
  {
    name: "Alan Curtis",
    link: "https://twitter.com/AlanJamesCurtis",
    pfp: "rio.jpeg",
    company: "Rio Network",
  },
  {
    name: "Bryant",
    link: "https://group.softbank/en",
    pfp: null,
    company: "Softbank",
  },
  {
    name: "Chunda McCain",
    link: "https://twitter.com/ChundaMcCain",
    pfp: "chunda.jpeg",
    company: "Ion Protocol",
  },
  {
    name: "CJ the 'Doughnut'",
    link: "https://twitter.com/CJCJCJCJ_",
    pfp: "cj.jpeg",
    company: "C4GG",
  },
  // { name: "Claire Ma", link: "https://twitter.com/ClaireMa12" },
  {
    name: "Darren Ye",
    link: "https://twitter.com/Darren_Yeah_",
    pfp: "darrenbing.jpeg",
    company: "Bing Ventures",
  },
  // { name: "DCF GOD", link: "https://twitter.com/dcfgod" },
  {
    name: "Delucidator",
    link: "https://twitter.com/delucinator",
    pfp: "delulu.jpeg",
    company: null,
  },
  {
    name: "Gabe Tramble",
    link: "https://twitter.com/zaddycoin",
    pfp: "shoal.png",
    company: "Shoal Research",
  },
  {
    name: "Gajesh Naik",
    link: "https://twitter.com/gajeshnaik",
    pfp: "gajesh.jpeg",
    company: "EigenLayer",
  },
  {
    name: "Garrett MacDonald",
    link: "https://twitter.com/bitcoingarrett",
    pfp: "BTCGarrett.png",
    company: "Permanent Ventures",
  },
  // { name: "Jack Xu", link: "https://twitter.com/headcpx?lang=en" },
  {
    name: "Jai Bhavnani",
    link: "https://twitter.com/Jai_Bhavnani",
    pfp: "jai.jpeg",
    company: "Waymont Co.",
  },
  {
    name: "kinnif",
    link: "https://twitter.com/0xkinnif",
    pfp: "kinnif.jpeg",
    company: "Fisher8 Capital",
  },
  {
    name: "llama intern",
    link: "https://twitter.com/llamaintern",
    pfp: "llamaintern.jpeg",
    company: "DefiLlama",
  },
  {
    name: "MEV Collector",
    link: "https://twitter.com/mevcollector",
    pfp: "mevcollector.jpeg",
    company: null,
  },
  {
    name: "Michael",
    link: "https://prismafinance.com/",
    pfp: null,
    company: "Prisma Finance",
  },
  // { name: "Pluto", link: "https://multibit.exchange/", pfp: "pluto.jpg", company: "MultiBit" },
  {
    name: "Robert Chen",
    link: "https://twitter.com/NotDeGhost",
    pfp: "nodeghost.png",
    company: "OtterSec",
  },
  {
    name: "Sam Kazemian",
    link: "https://twitter.com/samkazemian",
    pfp: "samk.jpeg",
    company: "Frax Finance",
  },
  {
    name: "Scott Moore",
    link: "https://twitter.com/notscottmoore",
    pfp: "scottmoore.jpeg",
    company: "Public Works",
  },
  {
    name: "Stephen Tong",
    link: "https://twitter.com/gf_256",
    pfp: "gfstephen.png",
    company: "Zellic",
  },
  {
    name: "Xan",
    link: "https://twitter.com/xaneth_",
    pfp: "xan.jpg",
    company: "Thala",
  },
  {
    name: "Xin Yan",
    link: "https://twitter.com/realyanxin",
    pfp: "xinyan.jpeg",
    company: "EthSign",
  },
];
