export const TransactionButton = {
  primary: "bg-[#70D6FF]",
  secondary: "bg-green-200",
  default:
    "text-black hover:bg-[#FF70A6] border-2 border-black rounded-full py-2 px-4 text-xl flex items-center gap-1 min-w-[120px] min-h-[48px] text-center justify-center dark:border-black",
  confirmed: "dark:border-green-200 dark:bg-oxfordblue dark:text-green-200",
  pending:
    "hover:bg-[#FF70A6] dark:border-floralwhite dark:text-floralwhite dark:bg-oxfordblue dark:hover:rosebonbon dark:hover:text-rosebonbon dark:hover:bg-oxfordblue dark:hover:border-rosebonbon opacity-90",
  disabled:
    "opacity-50 bg-gray-100 hover:bg-gray-100 dark:bg-oxfordblue dark:text-slategray dark:border-slategray",
};
